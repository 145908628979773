<template>
  <blocks />
</template>

<script lang="ts" setup>
import { QImg } from 'quasar';
import { Text } from 'vue';
import { NuxtLink } from '#components';
import type {
  BlockHeadingNode,
  BlockImageNode,
  BlockLinkNode,
  BlockListNode,
  BlockNode,
  BlockTextNode,
} from '~/types/strapi';

const props = defineProps({
  nodes: {
    type: Array as PropType<BlockNode[]>,
  },
  anchors: {
    type: Boolean,
  },
});

const textVNode = (node: BlockTextNode) => {
  if (node.bold || node.strikethrough || node.underline || node.italic) {
    return h(
      'span',
      {
        class: {
          'text-bold': node.bold,
          'text-italic': node.italic,
          'text-strike': node.strikethrough,
          'text-underline': node.underline,
        },
      },
      node.text,
    );
  }

  return h(Text, node.text);
};

const linkVNode = (node: BlockLinkNode) => {
  const url = new URL(node.url, useRequestURL());
  const relative = node.url.startsWith('/') || useRequestURL().host == url.host;

  return h(
    NuxtLink,
    {
      to: relative ? url.href.replace(url.origin, '') : url.href,
      target: relative ? '_self' : '_blank',
    },
    () => node.children?.map(n => render(n)) ?? [],
  );
};

const imageVNode = (node: BlockImageNode) => {
  const ratio = (node.image.width ?? 1) / (node.image.height ?? 1);

  const children: VNode[] = [];

  if (node.image.caption) {
    children.push(
      h(
        'div',
        { class: 'absolute-bottom text-subtitle2 text-center' },
        node.image.caption,
      ),
    );
  }

  return h(
    QImg,
    {
      src: node.image.url,
      placeholderSrc: node.image.placeholder,
      alt: node.image.alternativeText,
      noSpinner: !!node.image.placeholder,
      spinnerColor: 'primary',
      ratio: ratio,
      fit: 'contain',
      class: ['q-my-md'],
      style: {
        'max-height': '400px',
      },
    },
    () => children,
  );
};

const headingVNode = (node: BlockHeadingNode) => h(
  `h${node.level}`,
  { id: props.anchors ? kebabCase(firstTextNode(node)?.text ?? useId()) : undefined },
  node.children?.map(n => render(n)) ?? [],
);

const listVNode = (node: BlockListNode) => h(
  node.format == 'ordered' ? 'ol' : 'ul',
  node.children?.map(n => render(n)) ?? [],
);

const firstTextNode = (node: BlockNode) => node.children?.find(c => c.type == 'text') as BlockTextNode | undefined;

const render = (node: BlockNode): VNode | undefined => {
  switch (node.type) {
    case 'text':
      return textVNode(node);
    case 'heading':
      return headingVNode(node);
    case 'image':
      return imageVNode(node);
    case 'link':
      return linkVNode(node);
    case 'list':
      return listVNode(node);
    case 'list-item':
      return h('li', node.children?.map(n => render(n)) ?? []);
    case 'paragraph':
      return h('p', node.children?.map(n => render(n)) ?? []);
    case 'quote':
      return h('blockquote', node.children?.map(n => render(n)) ?? []);
    case 'code':
      return h('code', node.children?.map(n => render(n)) ?? []);
    default:
      return undefined;
  }
};

const blocks = () => {
  return h(
    'section',
    props.nodes?.map(n => render(n)),
  );
};
</script>
